import React, {useEffect} from 'react'
import {LoaderWithWatchdog} from "../../shared/LoaderWithWatchdog";
import {useNavigate, useParams} from "react-router-dom";
import {
    checkIsAnonymousUser, getAttemptedPage, getGotoUrl, setAttemptedPage,
    setCurrentPostPath,
    setCurrentSiteSection,
    setRedirectAfterLogin,
    setSignupMode,
    SiteSections
} from "../../util/storage";
import {
    clearPreventEmailSignInTimeOut, roleHasAccess,
} from "../../services/cognito";
import {createAnonAccountThunk} from "../../features/user/userSlice";
import {useAppDispatch} from "../../redux/hooks";
import {routes} from "../../constants/routes";
import {useTheme} from "@mui/material";

interface DirectPageAccessProps {
    pauseDirectAccess: boolean;
}

export const DirectPageAccess = (props: DirectPageAccessProps): React.ReactElement => {
    const params = useParams();
    const path = params.pagePath ? params.pagePath.split("#")[0] : undefined
    const pId = params.pageId ? params.pageId.split("#")[0] : undefined

    const pagePath = !! path ?
        !!pId ?
            path + "/" + pId
            : path
        : "/"
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const theme = useTheme()


    console.log("DirectPageAccess pagePath result: ", '/' + pagePath)
    setAttemptedPage('/' + pagePath)


    useEffect(() => {
        const AnonLoginClickHandler = (goTo: SiteSections, goToPostPath: string = "") => {
            if(goTo === "PostItem"){
                console.log('train goto', goToPostPath)
                setCurrentPostPath("/" + goToPostPath)
            }else{
                setCurrentSiteSection(goTo)
            }
            setSignupMode(0, false, true)
            clearPreventEmailSignInTimeOut()
            dispatch(createAnonAccountThunk())
        }

        const checkHasAccess = async () => {
            return await roleHasAccess()
        }


        let userLoggedIn = false
        checkHasAccess().then((result: boolean) => {
            userLoggedIn = result
            console.log("mail", "checking if user has access: do they?", result)
            if(result){
                navigate(
                    getAttemptedPage() === "/" ? routes.home
                    : getGotoUrl(true)
                )
            }
        })
        if(checkIsAnonymousUser() || userLoggedIn){
        }
        if(!props.pauseDirectAccess) {
            if (checkIsAnonymousUser() || userLoggedIn) {
                navigate(
                    getAttemptedPage() === "/" ? routes.home
                        : getGotoUrl(true)
                )
            } else {
                if (pagePath.toUpperCase() === 'FEEDBACK') {
                    setRedirectAfterLogin()
                    AnonLoginClickHandler("Feedback")
                } else if (pagePath.toUpperCase() === 'TRAINING') {
                    setRedirectAfterLogin()
                    AnonLoginClickHandler("Training")
                } else if (pagePath.toUpperCase().includes('TRAIN')) {
                    AnonLoginClickHandler("PostItem", pagePath)
                } else if (pagePath.toUpperCase().includes('POSTS')) {
                    setRedirectAfterLogin()
                    console.log('train newPath', pagePath)
                    AnonLoginClickHandler("PostItem", pagePath)
                } else if (pagePath.toUpperCase() === 'INSIGHTS') {
                    setRedirectAfterLogin()
                    AnonLoginClickHandler("Insights")
                } else {
                    navigate(
                        getAttemptedPage() === "/" ? routes.home
                            : getGotoUrl()
                    )
                }
            }
        }
    }, [navigate,pagePath, dispatch, theme.custom.isWhiteLabelled, props.pauseDirectAccess]);



    return (<LoaderWithWatchdog/>)
}