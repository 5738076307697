import React, {useState} from 'react';
import {Card, CardMedia, CardContent, Typography, IconButton, Box, useTheme} from "@mui/material";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import {useNavigate} from "react-router-dom";
import {routes} from "../../../constants/routes";
// import {timeSince} from "../../../lib/utils";
import {
    ImageCarouselFeedEntryPage,
    likeItemThunk,
    setSendingLikeTo, VideoFeedEntryPage
} from "../../../features/academyContent/academyContentSlice";
import {useAppDispatch} from "../../../redux/hooks";
import {cmsPageType} from "../../../constants/cms";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { checkIsAnonymousUser } from '../../../util/storage';
import {EmojiReplace} from "../../../lib/EmojiReplace";

interface PostProps {
  item: ImageCarouselFeedEntryPage | VideoFeedEntryPage;
}

export const Post: React.FC<PostProps> = (props: PostProps) => {
    const dispatch = useAppDispatch()
    const theme = useTheme();
    const navigate = useNavigate();
    const postPath = routes.academyPost.replace(":postId", props.item.id + "")

    const [likeSent, setLikeSent] = useState(false)
    const likeFailureFlip =
        (( (props.item.reactions.user_has_reacted ? 1 : 0) + (props.item.reactions.like_failures || 0)) % 2)
    const liked = !!likeFailureFlip ? !likeSent : likeSent
    const likeFailureReverseCount = (props.item.reactions.like_failures || 0)%2
    const likeCounted: number = props.item.reactions.user_has_reacted
        ? (likeSent ? -1 + likeFailureReverseCount : 0 - likeFailureReverseCount)
        : (likeSent ? 1 - likeFailureReverseCount : likeFailureReverseCount)

    const video_item = props.item.meta.type === cmsPageType.VideoFeedEntryPage ? props.item as VideoFeedEntryPage : undefined
    const image_item = props.item.meta.type === cmsPageType.ImageCarouselFeedEntryPage ? props.item as ImageCarouselFeedEntryPage : undefined

    return (
        <Card raised sx={{ mx: 'auto', mb: 2, '&:hover': {boxShadow: "0px 3px 8px 2px #AAAAAA !important", cursor: "pointer"}}} onClick={()=> navigate(postPath)}>
            <Box position="relative">
                <CardMedia
                    component="img"
                    height="250"
                    image={
                        image_item ? image_item.carousel_items[0].image.meta.download_url :
                        video_item ? video_item.first_frame_url : ""
                    }
                    alt="TrainingVideo image"
                />
                {video_item && (
                <>
                    <Box 
                        position="absolute" 
                        top={2} 
                        right={2} 
                        color="white"
                        bgcolor="rgba(1,36,72,0.5)"
                        height="30px"
                        width="30px"
                        borderRadius="25px"
                        mr={2}
                        mt={2}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <PlayCircleOutlineIcon />
                    </Box>
                    {/* <Box 
                        position="absolute" 
                        bottom={0} 
                        right={0} 
                        bgcolor="rgba(0, 0, 0, 0.5)" 
                        color="white" 
                        p={1}
                        borderRadius="4px"
                        mr={2}
                        mb={2}
                        height="25px"
                        width="55px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        00:24
                    </Box> */}
                </>
                )}
            </Box>
            <CardContent sx={{marginTop: -1, marginBottom: -1}}>
                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1}}>
                        <Typography variant="body1" sx={[{
                            color: theme.custom.communityPostTextColor,
                            fontWeight: '600',
                            flexGrow: 1,
                            mr: 1,
                            fontSize: '16px',
                            lineHeight: '20px',
                        },
                            // {
                            //     '&:hover': {
                            //         cursor: 'pointer',
                            //     },
                            // }
                        ]}>
                            <EmojiReplace stringText={props.item.title}/>
                        </Typography>
                    {!checkIsAnonymousUser() && (<IconButton
                        aria-label="like"
                        sx={{
                            flexShrink: 0,
                            backgroundColor: "#E4E8F2",
                            '&:hover': {
                            backgroundColor: "#B3BDC8",
                            },
                            width: "auto",
                            height: "32px", 
                            borderRadius: "16px", 
                            padding: "0 8px", 
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                        onClick={(event) => {
                            event.stopPropagation();
                            dispatch(setSendingLikeTo(props.item.id));
                            dispatch(likeItemThunk(props.item.id));
                            setLikeSent(!likeSent);
                        }}
                        >
                        { liked ?
                            <FavoriteIcon sx={{ color:theme.custom.communityLikertTextColor, fontSize: '16px' }}/>
                            :
                            <FavoriteBorderIcon sx={{ color:theme.custom.communityLikertTextColor,fontSize: '16px' }}/>
                        }
                        {props.item.reactions.count + likeCounted > 0 && 
                        <Typography sx={{ fontSize: '16px', marginLeft: "2px" }} color={theme.custom.communityLikertTextColor}>
                            {props.item.reactions.count + likeCounted}
                        </Typography>
}
                    </IconButton>
                    )}
                </Box>
                {/* <Typography sx={{ pt: '12px' }} color="#666666" fontSize="12px">
                    {timeSince(props.item.meta.first_published_at)}
                </Typography> */}
            </CardContent>
        </Card>
    );
};
