import React, {useEffect} from "react";
import {CircularProgress} from "@mui/material";
import {CenteredItemsBox} from "./styles";


export const LoaderWithDelayedRedirect = (props?: {size?: number|string, path: string, hideSpinner?:boolean}) => {

    useEffect(() => {
        const path = props?.path || "/"
        const timeoutId = setTimeout(() => {
            window.location.href = window.location.origin + path;

        }, 50) // Adjust the delay as needed
        return () => {
            clearTimeout(timeoutId)
        }
    }, [props?.path]);

    if(props?.hideSpinner){
        return (<></>)
    }

    return (
        <CenteredItemsBox sx={{flex: 1}}>
            {props ?
                <CircularProgress size={props.size}/>
                :
                <CircularProgress/>
            }
        </CenteredItemsBox>
    )
}