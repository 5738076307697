import React, {ChangeEvent, useState, useEffect} from 'react'
import {Box, Button, Modal, styled, TextField, Typography, List, ListItem} from "@mui/material";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import PrivacyTipOutlinedIcon from '@mui/icons-material/PrivacyTipOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
// import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import {useNavigate} from "react-router-dom";
import {routes} from "../../constants/routes";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {getUserSubscriptionsThunk, UserSubscription} from "../../features/subscriptions/subscriptionsSlice";
import {RootState} from "../../redux/store";
import {userInitiatedSignOut} from "../../services/cognito";
import {deleteUserProfileThunk, userLogout, ViaUserProfile} from "../../features/user/userSlice";
import {Links} from "../../constants/links";
import {checkIsAnonymousUser, isWebviewMode} from "../../util/storage";
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import { Avatar } from '@mui/material';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import {useTheme} from "@mui/material";
import {Analytics} from "../../lib/analytics";
import {SignInFooterComponent} from "../../shared/text-field/SignInFooterComponent";
import { EventRepeatOutlined } from '@mui/icons-material';
import { Header } from '../../shared/Header';

const StyledActions = styled(Box)(({theme}) => ({
    flex: "0 0 auto",
    width: "100%",
    fontSize: "18px",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.grey[900],
    position: "sticky",
    bottom: theme.custom.isWhiteLabelled ? 60 : 0,
    left: 0,
    zIndex: 2,
}));

const StyledAccountRow = styled(Box)(({theme})=>({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "24px 16px",
    color: theme.custom.darkerPrimaryColor,
    "&:hover": {
        cursor: "pointer"
    }
}))

const StyledAccountSectionIcon = styled(Typography)(()=>({
    padding: "0 16px 0 0",
    fontSize: "16px",
    fontWeight: 500,
}))
const StyledAccountSectionTitle = styled(Typography)(()=>({
    flexGrow: 1,
    fontSize: "16px",
    fontWeight: 500,
    paddingBottom: "4px",
}))
const StyledAccountSectionSignal = styled(Typography)(()=>({
    padding: "0 0 0 16px",
    fontSize: "12px",
}))
const StyledHr = styled('hr')(()=>({
    borderTop: "0.5px solid #B3BDC8",
    width: "100%",
    margin: "0",
}))

const StyledDeleteModalText = styled(Typography)(({theme})=>({
    fontSize: "18px",
    fontWeight: 500,
    color: theme.custom.darkerPrimaryColor,
    textAlign: "center"
}))

export const AccountManagementPage = (): React.ReactElement => {
    const theme = useTheme();
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const activeProSubscription = useAppSelector<UserSubscription|undefined>((state: RootState) => state.subscriptions.activatedSubscription);
    const [showDeleteProfileConfirmation, setShowDeleteProfileConfirmation] = useState<boolean>(false)
    const [deleteAccountConfirmText, setDeleteAccountConfirmText] = useState<string>("")
    const [showSignIn, setShowSignIn] = useState<boolean>(false)

    const profile = useAppSelector<ViaUserProfile|undefined>((state: RootState) => state.user.profile);

    useEffect(() => {
        dispatch(getUserSubscriptionsThunk())
    }, [dispatch]);

    const handleDeleteAccountButtonClick = () => {
        dispatch(deleteUserProfileThunk())
    }

    const handleDeleteAccountTextChange = (event: ChangeEvent<HTMLInputElement>) => {
        setDeleteAccountConfirmText(event.target.value)

    }

    useEffect(() => {
        Analytics.myAccountHomeView();
    }, [])

    const date = profile && profile.user ? new Date(profile.user.created_at * 1000) : new Date();
    // Array of month names
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    // Get the month and year
    const month = monthNames[date.getMonth()]; // getMonth() returns 0-11
    const year = date.getFullYear();
    // Format as "Month YYYY"
    const formattedDate = `${month} ${year}`;

    return (
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
            }}>
                <Header title='' userMenu={[]} headerMode='AccountPage'/>
                <Modal

                    open={showDeleteProfileConfirmation}
                    // onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{
                        outline: 0,
                        maxHeight: "60vh",
                        maxWidth: "95vw",
                        margin: "auto",
                        padding: "16px",
                        marginTop: "20vh",
                    }}
                >
                    <Box sx={{backgroundColor: "#FFFFFF", padding: "16px", display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <StyledDeleteModalText>
                            Deleting your account cannot be undone. You will permanently lose access to all Virtual Academies that you are a member of, including any of your PRO subscriptions or Academy Feedback across:
                        </StyledDeleteModalText>
                        <List sx={{ marginTop: "20px", marginBottom: "10px", width: "fit-content", listStyleType: 'disc', textAlign: "center" }}>
                            <ListItem sx={{ display: "list-item", color: theme.custom.darkerPrimaryColor }}>
                                <Typography>QPR Virtual Academy</Typography>
                            </ListItem>
                            <ListItem sx={{ display: "list-item", color: theme.custom.darkerPrimaryColor }}>
                                <Typography>Peterborough Virtual Academy</Typography>
                            </ListItem>
                            <ListItem sx={{ display: "list-item", color: theme.custom.darkerPrimaryColor }}>
                                <Typography>VIA Academy</Typography>
                            </ListItem>
                        </List>
                        <StyledDeleteModalText sx={{marginTop: "18px"}}>
                            If you’re sure, type DELETE below.
                        </StyledDeleteModalText>
                        <TextField sx={{margin: "16px 0"}} onChange={handleDeleteAccountTextChange}></TextField>
                        <Button
                            disabled={deleteAccountConfirmText !== "DELETE"}
                            sx={{margin: "16px auto"}}
                            variant="outlined"
                            color="error"
                            onClick={handleDeleteAccountButtonClick}>DELETE VIRTUAL ACADEMY ACCOUNT</Button>
                        <Button onClick={()=>setShowDeleteProfileConfirmation(false)}>No, keep my account</Button>
                    </Box>
                </Modal>

                {profile &&
                <Box 
                    sx={{
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    backgroundColor: '#B3BDC8', 
                    padding: '16px'
                    }}
                >
                    <Avatar 
                    sx={{ 
                        backgroundColor: '#EFEFEF', 
                        width: 56, 
                        height: 56 
                    }}
                    >
                    <SentimentVerySatisfiedIcon sx={{ color: theme.custom.darkerPrimaryColor }} />
                    </Avatar>
                    <Typography 
                    sx={{ 
                        color: theme.custom.darkerPrimaryColor,
                        fontSize: '22px', 
                        marginTop: '8px' 
                    }}
                    >
                    {profile.user.first_name}
                    </Typography>
                    <Typography 
                    sx={{ 
                        color: theme.custom.darkerPrimaryColor,
                        fontSize: '16px', 
                        marginTop: '4px' 
                    }}
                    > {profile.user.email}
                    </Typography>
                    <Box
                        style={{
                            margin: "24px 0 16px",
                            borderRadius: theme.custom.wl.globalBorderRadius,
                            borderWidth: "1px",
                            borderStyle: "solid",
                            padding: "8px 16px",
                            borderColor: theme.custom.darkerPrimaryColor,
                            textAlign: "center",
                            color: theme.custom.darkerPrimaryColor,
                        }}
                    >
                        <span
                            style={{
                                lineHeight: "20px",
                                fontWeight: 600,
                                textTransform: "uppercase",
                                display: 'block',
                                marginBottom: "3px"
                            }}
                        >ACADEMY ID: {profile.user.uuid.split('-')[0]}</span>
                        <span
                            style={{
                                fontSize: "12px",
                                lineHeight: "15px",
                                display: 'block',
                            }}
                        >Member since {profile && profile.user && formattedDate}</span>

                    </Box>
                </Box>
                }

                { !!activeProSubscription ?
                    <>
                        <StyledAccountRow onClick={() => navigate(routes.accountSubscriptions)} sx={{padding: "16px 16px"}}>
                            <StyledAccountSectionIcon><EventRepeatOutlined/></StyledAccountSectionIcon>
                            <StyledAccountSectionTitle>My Subscriptions</StyledAccountSectionTitle>
                            <StyledAccountSectionIcon><ChevronRightOutlinedIcon /></StyledAccountSectionIcon>
                        </StyledAccountRow>
                        <StyledHr/>
                    </> : null
                }
        <StyledAccountRow onClick={()=> window.location.href = "mailto:" + theme.custom.wl.supportEmail} sx={{padding: "12px 16px"}}>
            <StyledAccountSectionIcon><EmailOutlinedIcon/></StyledAccountSectionIcon>
            <StyledAccountSectionTitle>Contact us at {theme.custom.wl.supportEmail}</StyledAccountSectionTitle>
            <StyledAccountSectionSignal><OpenInNewIcon sx={{height: "16px"}}/></StyledAccountSectionSignal>
        </StyledAccountRow>
        <StyledHr/>
        {/* <StyledAccountRow sx={{padding: "12px 16px"}} onClick={()=> window.open(theme.custom.wl.faqURI, '_blank')}>
            <StyledAccountSectionIcon><QuestionMarkIcon/></StyledAccountSectionIcon>
            <StyledAccountSectionTitle>Frequently Asked Questions (FAQ)</StyledAccountSectionTitle>
        </StyledAccountRow> */}
        <StyledAccountRow sx={{padding: "12px 16px"}} onClick={()=> window.open(theme.custom.isWhiteLabelled ? theme.custom.wl.termsURI : Links.terms, '_blank')}>
            <StyledAccountSectionIcon><InsertDriveFileOutlinedIcon/></StyledAccountSectionIcon>
            <StyledAccountSectionTitle>Terms & Conditions</StyledAccountSectionTitle>
            {/*<StyledAccountSectionSignal><OpenInNewIcon sx={{height: "16px"}}/></StyledAccountSectionSignal>*/}
        </StyledAccountRow>
        <StyledAccountRow sx={{padding: "12px 16px"}} onClick={()=> window.open(theme.custom.isWhiteLabelled ? theme.custom.wl.privacyURI : Links.privacy, '_blank')}>
            <StyledAccountSectionIcon><PrivacyTipOutlinedIcon/></StyledAccountSectionIcon>
            <StyledAccountSectionTitle>Privacy Policy</StyledAccountSectionTitle>
            {/*<StyledAccountSectionSignal><OpenInNewIcon sx={{height: "16px"}}/></StyledAccountSectionSignal>*/}
        </StyledAccountRow>

        

        <StyledHr/>

                { !checkIsAnonymousUser() && !isWebviewMode() &&
                    <>
                    <StyledAccountRow sx={{padding: "12px 16px"}}>
                        <StyledAccountSectionIcon><DeleteForeverOutlinedIcon/></StyledAccountSectionIcon>
                        <StyledAccountSectionTitle onClick={()=> setShowDeleteProfileConfirmation(true)}>Delete Virtual Academy Account</StyledAccountSectionTitle>
                        {/*<StyledAccountSectionSignal><OpenInNewIcon sx={{height: "16px"}}/></StyledAccountSectionSignal>*/}
                    </StyledAccountRow>
                    <StyledHr/>
                    </>
                }
        <StyledAccountRow
            onClick={async () => {
                if(!checkIsAnonymousUser()) {
                    await userInitiatedSignOut();
                    dispatch(userLogout());
                    navigate(routes.home);
                }else {
                    setShowSignIn(true)
                }
            }}
            sx={{padding: "12px 16px"}}
        >
            { !isWebviewMode() && (
                !checkIsAnonymousUser() ?
                    <>
                        <StyledAccountSectionIcon><LogoutOutlinedIcon/></StyledAccountSectionIcon>
                        <StyledAccountSectionTitle>Sign Out</StyledAccountSectionTitle>
                    </>
                    :
                    <>
                        <StyledAccountSectionIcon><LoginOutlinedIcon/></StyledAccountSectionIcon>
                        <StyledAccountSectionTitle>Sign-In</StyledAccountSectionTitle>
                    </>
            )}

            {/*<StyledAccountSectionSignal><OpenInNewIcon sx={{height: "16px"}}/></StyledAccountSectionSignal>*/}
        </StyledAccountRow>
            { showSignIn &&
                <StyledActions>
                    <SignInFooterComponent
                        hideSubWall={()=> setShowSignIn(false)}
                        reloadSignIn={
                            async ()=> {
                            setShowSignIn(false)
                            setTimeout(()=> setShowSignIn(true), 0.001 )
                    }}
                        // onAction={props.onAction}
                    />
                </StyledActions>
            }
    </Box>
    )

}