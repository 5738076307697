import React, {useEffect} from 'react'
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {RootState} from "../../redux/store";
import {getCoachFeedbackRequestsThunk} from "../../features/user/userSlice";
import {Home} from "../home/Home";
import {FeedbackHome} from "./FeedbackHome";
import {checkIsAnonymousUser} from "../../util/storage";

export const FeedbackPageSelector = (): React.ReactElement => {
    const dispatch = useAppDispatch()

    const myCoachFeedbackVideos = useAppSelector((state: RootState) => state.user.coachFeedbackRequests)
    const orgId = useAppSelector<string>((state: RootState) => state.user.organisationId)

    useEffect(()=> {
        if(!checkIsAnonymousUser()){
            dispatch(getCoachFeedbackRequestsThunk(orgId))
        }
    },[dispatch, orgId])

    return(
        <>
            { !!myCoachFeedbackVideos.length ?
                <Home/>
                :
                <FeedbackHome/>
            }
        </>
    )
}