import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {
    createAnonAccount,
    deleteUserProfile, getCoachFeedBackRequests,
    getOrganisationsList,
    getViaUserProfile,
    updateUserProfile,
    userSignUp
} from "../../services/api";
import {generalMessage} from "../../constants/common";
import {IExtendedGeneralState} from "../../types/state";
import {resetToken, roleHasAccess, setIdToken, signIn, signOut, userInitiatedSignOut} from "../../services/cognito";
import {
    checkIsAnonymousUser, checkIsWhiteLabelled,
    clearIsAnonymousUser, clearSignInRetryFlags,
    clearSignupMode,
    getDefaultClubName, getGotoUrl,
    getSignupValues,
    isSignupMode, isWebviewMode, setIsAnonymousUser,
    setItem
} from "../../util/storage";
import {ErrorCode} from "../../constants/error";
import {routes} from "../../constants/routes";
import {decryptAES, generateRandom16} from "../../lib/utils";
import {AppConfig} from "../../config";
import {ICoachFeedbackResult} from "../../types/coachFeedback";
import {setFeedParentPages} from "../../lib/asyncUtils";
import {Theme} from "@mui/material";

export interface UserProfileOrgProfile {
    uuid: string
    avatar: string
    handle: string
    nationality: UserNationality
    pitch_post_count: number
    following_count: number
    // is_following: null?
    // is_follower: null
    followers_count: number
    // is_blocked: null
}

export interface OrgTheme {
    brand_color: string
}

export interface UserProfileOrganisation {
    uuid: string
    name: string
    category: number
    about: string
    profile: UserProfileOrgProfile
    abbreviation: string
    theme: OrgTheme
    stripe_public_key?: string
}

export interface UserNationality {
    code: string
    name: string
    flag: string
}

export interface UpdateTermsVersionRequest{
    uuid: string
    user: {
        accepted_terms_version: number;
    }
    referring_org?: string
}

export interface ViaUserProfile {
    uuid: string
    handle: string
    date_of_birth: number
    avatar: string
    nationality: UserNationality
    team_type: string
    playing_position: string
    organisation?: UserProfileOrganisation
    pitch_post_count: number
    following_count: number
    // is_following: null   ??
    // is_follower: null    ??
    followers_count: number
    organisation_role: string
    user: {
        uuid: string;
        created_at: number;
        accepted_terms_version: number;
        email: string;
        is_minor: boolean;
        first_name: string;
        last_name: string;
    }
    // is_blocked: null    ??
    // allowed_to_follow: null
    // date_of_graduation: null
    // followed_by_academies: boolean
    // private_followers: boolean
    // follow_requests: boolean
    // own_follow_request_status: null
    // follow_requests_status: null
}

export type IViaSignUpRequestData = ViaSignUpRequestData | AnonSignUpRequestData
export interface ViaSignUpRequestData {
    date_of_birth: number // UTC epoch in seconds(python), not milliseconds (javascript)
    marketing_opt_in: boolean
    organisation_id?: string
}

export interface AnonSignUpRequestData {
    anonymous_user: boolean
    organisation_id: string
}

export interface IViaSignUpResponseData {
    is_minor: boolean
}

interface userState extends IExtendedGeneralState {
    profile?: ViaUserProfile;
    profileFetched: boolean;
    allowToRedirect: boolean;
    organisationId: string;
    stripePublicKey: string;
    coachFeedbackRequests: ICoachFeedbackResult[];
}

// Define the initial state using that type
const initialState: userState = {
    loading: false,
    profile: undefined,
    profileFetched: false,
    error: null,
    allowToRedirect: false,
    organisationId: "",
    stripePublicKey: "",
    coachFeedbackRequests: [],
}

export const getUserProfileThunk = createAsyncThunk<ViaUserProfile, void|Theme>(
    'media/getViaUserProfileThunk',
    async (args, { rejectWithValue }) => {
        try {
            const gotten = await getViaUserProfile();
            clearSignupMode();
            clearSignInRetryFlags();
            clearIsAnonymousUser();
            if(!!args){
                await setFeedParentPages(args)
            }
            return gotten;
        } catch (err: any) {
            if(err.response.status === ErrorCode.notFound && isSignupMode()){
                try{
                    await userSignUp(getSignupValues());
                    console.log("fbq sign-up")
                    fbq('track', 'CompleteRegistration', {
                        content_name: 'User Profile Created'
                    });
                    const emptyProfile: ViaUserProfile = await getViaUserProfile()


                    // other default fields are set by the backend on creation.
                    let webDefaults: UpdateTermsVersionRequest =
                        {
                            uuid: emptyProfile.uuid,
                            user:
                                {
                                    accepted_terms_version: 4,
                                },
                            referring_org: !!getDefaultClubName() ? getDefaultClubName() : undefined,
                        } as UpdateTermsVersionRequest

                    const webDefaultValueProfile: ViaUserProfile = await updateUserProfile(webDefaults)
                    clearSignupMode()
                    if(!!args){
                        await setFeedParentPages(args)
                    }
                    return webDefaultValueProfile
                }catch (err: any){
                    return rejectWithValue(err.response.data);
                }
            }else{
                return rejectWithValue(err.response.data);
            }
        }
    }
)

interface CreateAnonAccountResponse {
    credentials: string
    iv: string
}
interface TempAccountCredentials {
    email: string
    password: string
}

export const loginAnon = async (email: string, pw: string): Promise<void> =>{
    console.log("signIn with", email, pw)
    const loginResult = await signIn(email, pw)
    console.log("login result", loginResult)
    await roleHasAccess();
    console.log("hasAccess done.", checkIsAnonymousUser())
    if (!checkIsAnonymousUser()) {
        console.log("not anon!")
        clearSignupMode()
        localStorage.removeItem("anon_email")
        localStorage.removeItem("anon_pw")
        await signOut();
        return;
    }
    console.log("setIdToken")
    await setIdToken();
    console.log("return loginResult")
    // return loginResult
}

export const createAnonAccountThunk = createAsyncThunk<TempAccountCredentials|undefined, void>(
    'user/createAnonAccount',
    async () => {
        const rand16 = generateRandom16()
        const gotten: CreateAnonAccountResponse = await createAnonAccount(rand16)
        const result = JSON.parse(await decryptAES(gotten.credentials, rand16, gotten.iv)) as TempAccountCredentials
        localStorage.setItem("anon_email", result.email)
        localStorage.setItem("anon_pw", result.password)
        console.log("temp user account creds: ", result)
        console.log("now login!")
        await loginAnon(result.email, result.password)
        console.log("now signup! if true: ", isSignupMode())
        if(isSignupMode()){
            await userSignUp(getSignupValues());
        }
        clearSignupMode()
        setIsAnonymousUser()
        window.location.href = getGotoUrl(true)

        // console.log("get profile!!")
        // dispatch(getUserProfileThunk())
        // window.location.href = routes.home
        return result || {email: "", password: ""} as TempAccountCredentials
    }
)

export const deleteUserProfileThunk = createAsyncThunk<number, void>(
    'user/deleteProfile',
    async (args,{dispatch}) => {
        const accessToken = sessionStorage.getItem("accessToken") || "";
        const requestStatus = await deleteUserProfile(accessToken)
        if(requestStatus === 200){
            await signOut();
            dispatch(userLogout());
            window.location.replace(routes.signIn);
        }
        return requestStatus;
    }
)

export interface GetOrganisationsResponse {
    organisations: UserProfileOrganisation[]
    abbreviation: string
}

export const getOrganisationIdThunk = createAsyncThunk<GetOrganisationsResponse, string>(
    'user/getOrganisationId',
    async (args) => {
        const gotten = await getOrganisationsList()
        return { organisations: gotten.results, abbreviation: args} as GetOrganisationsResponse;
    }
)

export interface CoachFeedBackRequestsResponse {
    results: ICoachFeedbackResult[]
}

export const getCoachFeedbackRequestsThunk = createAsyncThunk<CoachFeedBackRequestsResponse, string>(
    'user/getCoachFeedbackRequests',
    async (arg) => {
        const gotten = await getCoachFeedBackRequests(arg)
        console.log('gotten coachFeedbackResults', gotten)
        return {results: gotten} as CoachFeedBackRequestsResponse
    }
)

export const userSlice = createSlice({
    name: 'user',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        updateProfile: (state: userState, action: PayloadAction<ViaUserProfile>) => {
            state.profile = action.payload
        },
        setAllowUserToRedirect: (state: userState, action: PayloadAction<boolean>) => {
            state.allowToRedirect = action.payload;
        },
        userLogout: (state) => {
            state.error = null;
            state.profile = undefined;
            state.profileFetched = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getUserProfileThunk.pending, (state: userState) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(getUserProfileThunk.fulfilled, (state: userState, action: PayloadAction<ViaUserProfile>) => {
            state.loading = false;
            if(action.payload.user.is_minor && checkIsWhiteLabelled() && !checkIsAnonymousUser() && !isWebviewMode()){
                userInitiatedSignOut().then()
            }
            if(
            !state.profile
            && !action.payload.user.email.includes("anon@kaizhan.org")
            && !action.payload.user.email.includes("@privaterelay.appleid.com")
            ){
                console.log("fbq init")
                fbq('init', '734550458104849', {
                    em: action.payload.user.email
                });
                console.log("fbq SignIn")
                fbq('trackCustom', 'SignIn', {
                    method: 'social'
                });
            }
            state.profile = action.payload;
            state.profileFetched = true;
        })
        builder.addCase(getCoachFeedbackRequestsThunk.fulfilled, (state: userState, action: PayloadAction<CoachFeedBackRequestsResponse>) => {
            state.coachFeedbackRequests = action.payload.results;
        })
        builder.addCase(getUserProfileThunk.rejected, (state: userState, action: any) => {
            state.loading = false;
            state.error = {
                message: action.payload?.message ?? generalMessage,
                status: action.payload?.status,
            };
            state.profileFetched = true;
            resetToken();
        })
        builder.addCase(getOrganisationIdThunk.fulfilled, (state: userState, action: PayloadAction<GetOrganisationsResponse>) => {
            for(let i = 0;i<action.payload.organisations.length;i++){
                if(action.payload.organisations[i].abbreviation === action.payload.abbreviation) {
                    setItem("organisation_id", action.payload.organisations[i].uuid)
                    state.organisationId = action.payload.organisations[i].uuid
                    if(!!action.payload.organisations[i].stripe_public_key){
                        state.stripePublicKey = action.payload.organisations[i].stripe_public_key || AppConfig.STRIPE_PUBLIC_KEY
                    }else{
                        state.stripePublicKey = AppConfig.STRIPE_PUBLIC_KEY as string
                    }
                }
            }
        })
    },
});


export const {userLogout, setAllowUserToRedirect} = userSlice.actions;
export default userSlice.reducer
